import { marked } from 'marked';
import { renderer } from './lib/renderer';





const prismHighlighting = () => {
  Prism.highlightAll();
};



window.addEventListener('load', () => {
  // 表示する要素を取得
  const bodyContent = document.getElementById('bodyContent');

  if(!bodyContent) return;

  // パラメータを取得
  const parameters = JSON.parse(document.getElementById('__DATA__').textContent);

  // マークダウンをマークアップに変換して書き込む
  marked.use({ renderer });
  bodyContent.innerHTML = marked.parse(parameters.article);

  prismHighlighting();
});
