// Override function
export const renderer = {
  link(href, title, text) {
    if( /youtube.com|youtu.be/.test(text) === true ) {
      let id;

      if( /youtube.com/.test(text) ) {
        const regex = /(?<=\=).*$/g;
        const matches = text.match(regex);
        id = matches[0];
      }

      if( /youtu.be/.test(text) ) {
        const regex = /(?<=youtu.be\/).+(?=\?)/g;
        const matches = text.match(regex);
        id = matches[0];
      }

      return `<iframe src="https://www.youtube.com/embed/${id}" class="embed-youtube" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowfullscreen></iframe>`;
    } else {
      return `<a href="${href}">${text}</a>`;
    }

  }
};
